body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skipLink {
  height: 1px;
  width: 1px;
  display: block;
  overflow: hidden;
}

.skipLink:focus {
  height: auto;
  width: 168px;
  position: absolute;
  background-color: #222222;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  padding: 4px;
  border: 1px solid white;
  border-radius: 8px;
  z-index: 100000;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.6);
}
